<template>
  <div id="purchase-completed" class="page more-depth">
    <div class="container">
      <div class="page-header">
        <div class="page-header__title">
          이용권 구매 완료
        </div>
      </div>

      <div class="page-body">
        <!-- <div class="product">
          <div class="product-name">
            {{ purchaseProductName }}
          </div>
          <div class="product-price">
            {{ purchaseProductPrice }}
          </div> -->
        <!-- </div> -->
        <div class="container">
          <div class="text">
            결제완료되었습니다!
          </div>
          <div class="button-area">
            <button type="button" class="ui-submit-button" v-button @click="$router.push({ name: 'MypageCoupons' })">
              <span class="ui-submit-button__text">
                이용권 내역 보러가기
              </span>
            </button>
            <button type="button" class="ui-submit-button activated" v-button @click="$router.push({ name: 'Stores' })">
              <span class="ui-submit-button__text">
                물품 맡기러 가기
              </span>
            </button>
          </div>
        </div>
<!-- 
        <div class="receipt">
          <div class="receipt__product">
            <dl>
              <dt>결제금액</dt>
              <dd>{{ originAmount }}</dd>
            </dl>
            <dl>
              <dt>할인쿠폰</dt>
              <dd>{{ couponAmount }}</dd>
            </dl>
            <dl>
              <dt>할인코드</dt>
              <dd>{{ discountCodeAmount }}</dd>
            </dl>
            <dl>
              <dt>총 합계</dt>
              <dd>{{ paidAmount }}</dd>
            </dl>
          </div> -->
<!-- 
					<div class="receipt__payment">
						<dl>
              <dt>결제카드</dt>
              <dd>{{ cardInfo }}</dd>
            </dl>
            <dl>
              <dt>구매내역</dt>
              <dd>{{ purchaseProductName }}</dd>
            </dl>
            <dl>
              <dt>거래일시</dt>
              <dd>{{ transactionCreatedAt }}</dd>
            </dl>
						<dl>
							<dt>거래유형</dt>
              <dd>{{ transactionType }}</dd>
						</dl>
            <dl>
              <dt>할부</dt>
              <dd>{{ installment }}</dd>
            </dl>
					</div> -->
        <!-- </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
	props : {
		order_id: {
			type: [String, Number]
		}
	},

	computed: {
		order() {
      return this.$store.getters["orders/GET_ITEM"]({ key: "order_id", value: this.order_id });
    },
	},

	data() {
		return {
			transaction: null
		}
	},

	computed: {
		// purchaseCompletedHeader() {
		// 	if(this.order?.product?.)
		// 	return "프리패스권 구매 완료"
		// }
	},

	methods: {
		// async getTransaction() {
		// 	try {
    //     res = await this.$store.dispatch("billings/getTransaction", { id: this.order[which + "_tid"] });

    //     this.$data.transaction = res;

    //     this.openModal("creditCardSlipModal");
    //   } catch (e) {
    //     this.$store.commit("alert/ADD_ITEM", { message: this.$__t(e), status: "error" });
    //   }
		// }	
	}
}
</script>

<style scoped lang="scss">
.page-body {
  display: flex;
  align-items: center;
  justify-content: center;

  .container {
    align-self: center;
    
    .text {
      font-size: 24px;
      font-weight: 500;
      text-align: center;
    }

    .button-area {
      padding: 12px;
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
  }
}
</style>